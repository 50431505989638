<template>
  <div class="body" @click="hidePanel">
    <van-nav-bar left-text="❮返回" @click-left="$router.replace('/index')">
      <!-- <template #right>
        <p @click="btnshow">{{ username }}<van-icon name="play" /></p>
        <van-button v-if="btnisshow" class="btn" type="warning" @click="exit" id="myPanel">退出登录</van-button>
      </template>-->
    </van-nav-bar>
    <div class="from">
      <h3>我的评估</h3>
      <van-form @submit="onSubmit" input-align="center">
        <van-field
          class="myinput1"
          v-model="newstime"
          name
          label="评估日期"
          @click="showPicker = true"
          clearable
          clear-trigger="always"
          @clear="from.stime = ''"
          colon
          @focus="stopKey"
        />
        <span class="span">-</span>
        <van-field
          class="myinput2"
          v-model="newetime"
          @click="showPicker2 = true"
          clearable
          clear-trigger="always"
          @clear="from.etime = ''"
          @focus="stopKey"
        />
        <van-field v-model="from.name" label="学生姓名" input-align="left" class="userName" colon />
        <van-field
          v-model="from.mobile"
          label="手机号"
          input-align="left"
          class="phone"
          :maxlength="11"
          colon
        />
        <van-button class="serchbtn" type="warning" @click="serch">
          <van-icon name="search" />搜索
        </van-button>
      </van-form>
      <div class="mycard" v-for="(item, index) in studentlist" :key="index">
        <div class="text">
          <span style="margin-right: 0.2rem">{{ item.uid }}</span>
          <span>{{ item["stu_name"] }}</span>
        </div>
        <div class="num">
          <span>评估次数：{{ item["num"] }}次</span>
        </div>
        <div class="info">
          <p class="numtop">
            <van-icon name="calendar-o" />
            评估类型：{{ ["MUST评估","能力评估","职能评估","口肌评估","感统障碍评估","口腔障碍评估",][item["e_type"]] }}
          </p>
          <p
            class="time"
          >{{ moment(Number(item.k_date * 1000)).format("YYYY-MM-DD") }} {{ item.k_time }}</p>
        </div>
        <van-button
          @click="pingu(item)"
          class="pingubtn"
          type="danger"
        >{{ ["", "开始评估", "", "已完成", "继续评估"][item.k_status] }}</van-button>
      </div>
    </div>
    <!-- 评估日期弹窗 -->
    <van-calendar
      v-model="showPicker"
      :min-date="minDate"
      @confirm="onConfirm"
      @cancel="showPicker = false"
    />
    <van-calendar
      v-model="showPicker2"
      :min-date="minDate"
      @confirm="onConfirm2"
      @cancel="showPicker2 = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import { Notify } from "vant";
import {
  getname,
  removetoken,
  removeed,
  removetype,
  removename,
  removeLabelId,
} from "/src/api/token.js";
import { setuid, setreviewid } from "/src/api/userdata.js";
import { getteacherlist, getstudent } from "../../api/index";
export default {
  data() {
    return {
      moment,
      username: "",
      btnisshow: false,
      minDate: new Date("2010/01/01"),
      from: {
        name: "",
        mobile: "",
        stime: "",
        etime: "",
      },
      showPicker: false,
      showPicker2: false,
      studentlist: [],
      newstime: "",
      newetime: "",
    };
  },
  mounted() {
    this.getlist();
    this.getname();
  },
  methods: {
    serch() {
      getstudent(this.from).then((res) => {
        if (res.code === 1 && res.data.length > 0) {
          this.studentlist = res.data;
        } else {
          this.studentlist = [];
        }
      });
    },
    getname() {
      const teachername = getname();
      this.username = teachername;
    },
    getlist() {
      getteacherlist().then((res) => {
        if (res.code === 1) {
          this.studentlist = res.data;
        } else if (res.code === 9990) {
          Notify({ type: "danger", message: res.msg });
          this.$router.push({
            name: "login",
          });
        }
      });
    },
    btnshow(e) {
      e.stopPropagation();
      this.btnisshow = !this.btnisshow;
    },
    hidePanel(event) {
      let myPanel = document.getElementById("myPanel");
      if (myPanel) {
        if (!myPanel.contains(event.target)) {
          this.btnisshow = false;
        }
      }
    },
    exit() {
      removetype();
      removeed();
      removetoken();
      removename();
      removeLabelId();
      this.$router.push({
        name: "login",
      });
    },
    onConfirm(time) {
      const newtime = moment(time).format("YYYY/MM/DD 00:00:00");
      let ntime =
        new Date(Date.parse(newtime.replace(/-/g, " "))).getTime() / 1000;
      this.newstime = newtime.replace(" 00:00:00", "");
      this.from.stime = ntime;
      this.showPicker = false;
    },
    onConfirm2(time) {
      const newtime = moment(time).format("YYYY/MM/DD 23:59:59");
      let ntime =
        new Date(Date.parse(newtime.replace(/-/g, " "))).getTime() / 1000;
      this.newetime = newtime.replace(" 23:59:59", "");
      this.from.etime = ntime;
      this.showPicker2 = false;
    },
    pingu(val) {
      setuid(val.uid);
      setreviewid(val.id);
      // console.log(val);
      if (val.e_type == 5) {
        this.$router.push({
          name: "oralSnag",
          query: {
            uid: val.uid,
            review_id: val.id,
            ass_time: val.k_date,
            time: val.k_time,
          },
        });
      } else if (val.e_type == 3) {
        this.$router.push({
          name: "oralAssessment",
          query: {
            uid: val.uid,
            review_id: val.id,
            ass_time: val.k_date,
            time: val.k_time,
            ass_num: val.num,
            eva_type: val.e_type,
          },
        });
      } else {
        this.$router.push({
          name: "startpingu",
          query: {
            ass_num: val.num,
            eva_type: val.e_type,
          },
        });
      }
    },
    onSubmit() { },
    stopKey() {
      document.activeElement.blur();
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  background: url(src/img/bj.png) top left/100% auto no-repeat #f8f8f8;
  button {
    border: none;
  }
  .van-nav-bar {
    background: none;
    /deep/ .van-nav-bar__left,
    .van-nav-bar__right {
      font-size: 0.16rem;
      color: #333333;
      .van-nav-bar__text {
        color: #333;
      }
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      // font-weight: bold;
    }
    .van-icon {
      font-size: 0.1rem;
      color: #ffaa20;
      transform: rotate(90deg);
    }
    .btn {
      width: 1.03rem;
      height: 0.4rem;
      position: absolute;
      right: 0.1rem;
      top: 0.4rem;
      background: #fff;
      box-shadow: 0 0 0.1rem 0.01rem rgba(151, 0, 0, 0.11);
      color: #333;
      border-radius: 0.06rem;
      font-size: 0.14rem;
      padding: 0.12rem;
      .van-button__content::before {
        content: "";
        width: 0.14rem;
        height: 0.14rem;
        background: url("./src/img/ICON2x.png") top left/100% no-repeat;
        margin-right: 0.04rem;
      }
    }
    &::after {
      border: none;
    }
  }
  .from {
    // background: linear-gradient(
    //   180deg,
    //   rgba(255, 255, 255, 0.1) 0%,
    //   rgb(255 230 230 / 70%) 100%
    // );
    border-radius: 0.3rem 0.3rem 0 0;
    min-height: calc(100vh - 0.46rem);
    padding: 0 0.16rem 0.16rem;
    h3 {
      margin: 0;
      font-size: 0.18rem;
      color: #333;
    }
    .van-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // padding: 0 .2rem;
      .van-cell {
        width: fit-content;
        line-height: 0.34rem;
        padding: 0.14rem 0.2rem 0.14rem 0;
        background: none;
        &::after {
          display: none;
        }
      }
      .myinput2 {
        padding-left: 0.1rem;
      }
      /deep/ .van-field__label {
        width: auto;
        color: #333;
        padding-right: 0.05rem;
        margin-right: 0;
        font-size: 0.14rem;
      }
      /deep/ .van-field__value {
        width: 1.8rem;
        height: 0.32rem;
        background: #ffffff;
        border-radius: 0.06rem;
        border: 1px solid #efefef;
        font-size: 0.14rem;
      }
      .userName {
        padding: 0 0.2rem 0 0;
        /deep/ .van-field__value {
          width: 1.3rem;
          input {
            padding: 0 0.1rem;
          }
        }
      }
      .phone {
        padding: 0 0.2rem 0 0;
        /deep/ input {
          padding: 0 0.1rem;
        }
      }
      .span {
        font-size: 0.14rem;
        line-height: 0.32rem;
      }
      .serchbtn {
        width: 0.92rem;
        height: 0.34rem;
        background: #f14947;
        border-radius: 0.06rem;
        font-size: 0.14rem;
        padding: 0 0.15rem;
        letter-spacing: 0.01rem;
        .van-icon {
          font-size: 0.16rem;
          vertical-align: bottom;
        }
      }
    }
    .mycard {
      // width: 95%;
      height: 0.72rem;
      background: #ffffff;
      border-radius: 0.06rem;
      margin: 0.08rem auto;
      padding: 0.14rem 0.27rem 0.14rem 0.16rem;
      font-size: 0.14rem;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      color: #333;
      .text {
        width: 20%;
      }
      &:last-child {
        margin: 0.08rem auto 0;
      }
      .num {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &::before,
        &::after {
          content: "";
          display: inline-block;
          width: 1px;
          height: .42rem;
          background: linear-gradient(
            244deg,
            rgba(204, 204, 204, 0) 0%,
            rgba(204, 204, 204, 1) 50%,
            rgba(204, 204, 204, 0) 100%
          );
        }
        span {
          // margin: 0 0.8rem;
        }
      }
      .info {
        flex: 1;
        padding-left: 0.5rem;
        .van-icon {
          font-size: 0.18rem;
          color: #ffaa20;
          vertical-align: text-bottom;
        }
        .time {
          font-size: 0.12rem;
          color: #999;
          margin-left: 0.2rem;
        }
      }
      .pingubtn {
        width: 0.9rem;
        height: 0.34rem;
        background: #f14947;
        border-radius: 0.06rem;
        padding: 0 0.15rem;
        font-size: 0.14rem;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .body .from .van-form /deep/ .van-field__value {
    width: 1.4rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 0.8rem;
  }
}
@media screen and (min-width: 479px) {
  .body .from .van-form /deep/ .van-field__value {
    width: 1.4rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 0.8rem;
  }
}
@media screen and (min-width: 639px) {
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 1rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 768px) {
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 1.8rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 820px) {
  .body .from .van-form /deep/ .van-field__value {
    width: 1rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 0.7rem;
  }
}
@media screen and (min-width: 821px) {
  .body .from .van-form /deep/ .van-field__value {
    width: 1.2rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 0.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .body .from .van-form /deep/ .van-field__value {
    width: 1.6rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value {
    width: 0.8rem;
  }
}
</style>
